import { mediaQuery } from 'styled-media-queries';

// Totally optional: define some re-usable breakpoint numbers.
export const breakpoint = {
  widedesktop: 1500,
  desktop: 1100,
  tablet:  500,
  mobile:  0,
};
 
export const media = {
  mobile: mediaQuery`(max-width: ${breakpoint.desktop}px)`,
  tabletMin: mediaQuery`(min-width: ${breakpoint.tablet}px)`,
  tablet: mediaQuery`(min-width: ${breakpoint.tablet}px) and (max-width: ${breakpoint.desktop}px)`,
  desktop: mediaQuery`(min-width: ${breakpoint.desktop}px)`,
  widedesktop: mediaQuery`(min-width: ${breakpoint.widedesktop}px)`,
  print:     mediaQuery`print`,
};

export type Theme = {
  colors: {
    red: string;
    pink: string;
    black: string;
    white: string;
    whiteTrans: string;
    background: string;
    bright: string;
    lightgray: string;
    gray: string;
    darkgray: string;
    dark: string;
    invertFilter: string;
    modalBackground: string;
    highlight: string;
  };
  backgroundImage: string;
  headerImage: string;
  frameImage: string;
  logoImage: string;
}

const lightTheme:Theme = {
  colors: {
    red: "#AD0000",
    pink: "#ffd8d8",
    black: "#000000",
    white: "#ffffff",
    whiteTrans: "#ffffffbd",
    background: "white",
    bright: "#fefefe",
    lightgray: "#eee",
    gray: "#ccc",
    darkgray: "#999",
    dark: "#434343",
    invertFilter: "none",
    modalBackground: "#ffffff88",
    highlight: "#ff9100c5"
  },
  backgroundImage: 'url("/images/art2.png")',
  headerImage: 'url("/images/header-bg.png")',
  frameImage: 'url("/images/frame-small.png")',
  logoImage: '/images/logo-new2.png'
};

const darkTheme:Theme = {
  colors: {
    red: "#b51717",
    pink: "#5e3333",
    black: "#dadada",
    background: "#222222",
    white: "#2d2d2d",
    whiteTrans: "#202020",
    bright: "#1d1d1d",
    lightgray: "#303030",
    gray: "#2e2e2e",
    darkgray: "#666",
    dark: "#bfbfbf",
    invertFilter: "invert(100%) hue-rotate(180deg) saturate(40) contrast(1.5)",
    modalBackground: "#00000088",
    highlight: "#ff9100c5"
  },
  backgroundImage: 'url("/images/art-dark.png")',
  headerImage: 'url("/images/header-bg-dark.png")',
  frameImage: 'url("/images/frame-small-dark.png")',
  logoImage: '/images/logo-new2-dark.png',
}

export const themeList = [
  lightTheme, darkTheme
];


export default darkTheme;
